.help__container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: #4448;
}

.help__container > div {
  width: calc(100% - 80px);
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 20px;
  border-radius: 20px;
  border: darkgoldenrod 10px solid;
  background-color: #444;
  color: white;
}

.poker-patience__unlimited .help__container > div {
  border-color: #57d; 
}

@media (max-aspect-ratio: 680/700) {
  .help__container {
    background-color: transparent;
  }

  .help__container > div {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media (max-width: 345px) {
  .stats__container > div {
    width: 100%;
    border: 0;
    border-radius: 0;
  }
}

.help {
  display: grid;
  grid-template-columns: 1fr;
  row-gap: 10px;
}

.help__hand {
  display: grid;
  grid-template-columns: 150px 1fr;
  row-gap: 4px;
}

.help__hand-name {
  font-weight: bold;
  color: darkgoldenrod;
}

.poker-patience__unlimited .help__hand-name {
  color: #57d; 
}

.help__hand-score {
  text-align: right;
  color: darkgoldenrod;

  &::after {
    content: " points";
  }
}

.poker-patience__unlimited .help__hand-score {
  color: #57d; 
}

.help__hand-description {
  grid-column: 1/3;
}

.help__hand-cards {
  grid-column: 1/3;
  display: grid;
  grid-template-columns: 40px 40px 40px 40px 40px;
  grid-template-rows: 40px;
  gap: 1px;
}

.help__hand-cards div {
  background-color: white;
  gap: 1px;
  font-size: 24px;

  & .card__number {
    top: -4px;
    left: 3px;
  }

  & .card__suit {
    top: 4px;
    left: -2px;
  }
}