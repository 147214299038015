.next-card__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.next-card__title {
  text-align: center;
  margin-bottom: 10px;
}

.next-card {
  width: calc((100vmin - 44px) / 5);
  font-size: 8vmin;
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1px;
  border: black 1px solid;
  background-color: white;
}

.next-card__square {
  aspect-ratio: 1;
}

@media (max-aspect-ratio: 900/700) {
  .next-card {
    width: calc((80vmin - 44px) / 5);
  }
}

@media (max-aspect-ratio: 750/700) {
  .next-card {
    width: calc((70vmin - 44px) / 5);
  }
}

@media (max-aspect-ratio: 690/700) {
  .next-card {
    width: calc((60vmin - 44px) / 5);
  }
}

@media (max-aspect-ratio: 600/700) {
  .next-card {
    width: calc((70vmin - 44px) / 5);
  }
}

@media (max-aspect-ratio: 540/700) {
  .next-card {
    width: calc((80vmin - 44px) / 5);
  }
}

@media (max-aspect-ratio: 440/700) {
  .next-card {
    width: calc((100vmin - 44px) / 5);
  }
}