.stats__container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: #4448;
}

.stats__container > div {
  width: calc(100% - 80px);
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 20px;
  border-radius: 20px;
  column-gap: 20px;
  row-gap: 6px;
  white-space: nowrap;
  border: darkgoldenrod 10px solid;
  background-color: #444;
  color: white;
}

.poker-patience__unlimited .stats__container > div {
  border-color: #57d; 
}

@media (max-aspect-ratio: 680/700) {
  .stats__container {
    background-color: transparent;
  }

  .stats__container > div {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media (max-width: 345px) {
  .stats__container > div {
    width: 100%;
    border: 0;
    border-radius: 0;
  }
}

.stats__stats {
  display: grid;
  grid-template-columns: 0fr 0fr 1fr;
  row-gap: 4px;
  column-gap: 20px
}

.stats__stat {
  font-weight: bold;
}

.stats__main_value {
  grid-column: 2 / 4;
}

.stats__streak, .stats__wld, .stats__average-score-diff {
  margin-top: 10px;
}

.stats__daily-streak {
  margin-top: 3px;
}

.stats__average-score-diff {
  grid-column: 1 / 4;
}