.card {
  width: 100%;
  height: 100%;
}

.card_inner {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.card__number {
  position: relative;
  flex: 1 0 10px;
  text-align: center;
}

.card__suit {
  position: relative;
  flex: 1 0 10px;
  text-align: center;
}

.card__heart {
  color: red;
}

.card__diamond {
  color: rgb(200, 0, 0);
}

.card__spade {
  color: black;
}

.card__club {
  color: rgb(80, 80, 80);
}