.intro__container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: #4448;
}

.intro__container > div {
  width: calc(100% - 80px);
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 20px;
  border-radius: 20px;
  border: darkgoldenrod 10px solid;
  background-color: #444;
  color: white;
}

.intro__title {
  width: 100%;
  text-align: center;
  font-size: x-large;
  font-weight: bold;
}

.intro__play {
  width: 100%;
  margin-top: 30px;
  text-align: center;
}

.intro__scoring, .intro__unlimited, .intro__daily {
  margin: 20px;
}

.intro__scoring span {
  background-color: darkgoldenrod;
  border: darkgoldenrod 1px solid;
  padding: 2px 10px;
  border-radius: 6px;
  cursor: pointer;
}

.poker-patience__unlimited .intro__scoring span {
  background-color: #57d;
  border: #57d 1px solid;
}

.intro__daily span {
  background-color: darkgoldenrod;
  border: darkgoldenrod 1px solid;
  padding: 2px 10px;
  border-radius: 6px;
  cursor: pointer;
}

.intro__unlimited span {
  background-color: #57d;
  border: #57d 1px solid;
  padding: 2px 10px;
  border-radius: 6px;
  cursor: pointer;
}

.poker-patience__unlimited .intro__scoring {
  border-color: #57d;
}

.poker-patience__unlimited .intro__container > div {
  border-color: #57d; 
}

.intro__instructions {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  column-gap: 10px;
}

.intro__show_instructions span {
  border: darkgoldenrod 1px solid;
  padding: 2px 10px;
  border-radius: 6px;
  cursor: pointer;
}

.poker-patience__unlimited .intro__show_instructions span {
  border-color: #57d;
}

.intro__show_instructions.active span {
  background-color: darkgoldenrod;
}

.poker-patience__unlimited .intro__show_instructions.active span {
  background-color: #57d;
}

.intro__container .help {
  margin-top: 10px;
}

@media (max-aspect-ratio: 680/700) {
  .intro__container {
    background-color: transparent;
  }

  .intro__container > div {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media (max-width: 345px) {
  .intro__container > div {
    width: 100%;
    border: 0;
    border-radius: 0;
  }
}
