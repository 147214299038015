.poker-patience {
  display: flex;
  flex-direction: column;
  user-select: none;
}

.poker-patience__title {
  text-align: center;
  font-size: x-large;
  font-weight: bold;
  margin-block: 0;
  margin-inline: 0;
}

.poker-patience__main {
  width: 100%;

  display: flex;
  align-items: center;
}

@media (max-aspect-ratio: 690/700) {
  .poker-patience__title--overlay {
    display: none;
  }

  .poker-patience__main--overlay {
    display: none;
  }
}

.poker-patience__board {
  position: relative;
  width: calc(100vmin - 48px);
  font-size: 8vmin;
}

.poker-patience__info {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  align-items: center;
}

.poker-patience-header {
  padding: 10px;
  color: darkgoldenrod;
  font-size: x-large;
  display: grid;
  grid-template-columns: 90px 1fr 90px;

  &__about {
    text-align: left;
  }

  &__actions {
    text-align: right;
  }

  &__scoring {
    line-height: 1em;
    margin-left: 0.2em;
  }
}

@media (max-width: 420px) {
  .poker-patience-header {
    grid-template-columns: 35px 1fr 35px;
  }
}

.poker-patience__unlimited .poker-patience-header {
  color: #57d;
}

.poker-patience__info .break {
  flex-basis: 100%;
  height: 0;
  margin: 1vmin 0;
}

.poker-patience__message {
  position: fixed;
  font-size: large;
  bottom: 0;
  width: 100%;
  text-align: center;
  z-index: 100;
  border-top: white 1px solid;
}

.poker-patience__message div {
  background-color: darkgoldenrod;
  padding: 5px 20px;

  .poker-patience-unlimited {
    color: white;
    cursor: pointer;
  }
}

.poker-patience__unlimited .poker-patience__message div {
  background-color: #57d;
}

.robot-scores {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1vmin;
}

.robot-scores *:nth-child(3) {
  grid-column: 1 / span 2;
}

.poker-patience__play_unlimited,
.poker-patience__play_daily {
  cursor: pointer;
  text-align: center;
}

.poker-patience-unlimited {
  color: #57d;
  font-weight: bold;
}

.poker-patience-unlimited__narrow {
  display: none;
}

@media (max-width: 430px) {
  .poker-patience-unlimited__narrow {
    display: inline;
  }

  .poker-patience-unlimited__wide {
    display: none;
  } 
}

.poker-patience-daily {
  color: darkgoldenrod;
  font-weight: bold;
}

.card {
  font-size: 8vmin;
}

.card__number {
  top: -2.5vmin;
  left: 1vmin;
}

.card__suit {
  top: 2.5vmin;
  left: -1vmin;
}

@media (max-aspect-ratio: 920/700) {
  .robot-scores {
    grid-template-columns: 1fr;
  }

  .robot-scores *:nth-child(3) {
    grid-column: auto;
  }

  .poker-patience__info .break {
    flex-basis: 100%;
    height: 0;
  }
}

@media (max-aspect-ratio: 850/700) {
  .card {
    font-size: 7vmin;
  }

  .card__number {
    top: -2.25vmin;
    // left: 1.5vmin;
  }

  .card__suit {
    top: 2.25vmin;
    // left: -1.5vmin;
  }
}

@media (max-aspect-ratio: 750/700) {
  .card {
    font-size: 6vmin;
  }

  .card__number {
    top: -2vmin;
    // left: 1.5vmin;
  }

  .card__suit {
    top: 2vmin;
    // left: -1.5vmin;
  }
}

@media (max-aspect-ratio: 690/700) {
  .poker-patience__main {
    flex-direction: column;
  }

  .poker-patience__board {
    width: 60vmin;
  }

  .poker-patience__info {
    flex-grow: 0;

    flex-direction: row;
    margin-bottom: 10px;

    flex-wrap: wrap;
    align-items: normal;
    justify-content: center;
  }

  .poker-patience__info .break:nth-child(2) {
    flex-basis: 8vmin;
  }

  .robot-scores {
    width: calc(100% - 20px);
    max-width: 400px;
    grid-template-columns: calc(33.3% - 2vmin) calc(33.3% - 2vmin) calc(33.3% - 2vmin);
    gap: 3vmin;
  }

  .card {
    font-size: 5vmin;
  }

  .card__number {
    top: -1.75vmin;
    // left: 1.5vmin;
  }

  .card__suit {
    top: 1.75vmin;
    // left: -1.5vmin;
  }
}

@media (max-aspect-ratio: 600/700) {
  .poker-patience__board {
    width: 70vmin;
  }

  .card {
    font-size: 6vmin;
  }

  .card__number {
    top: -2vmin;
    // left: 1.5vmin;
  }

  .card__suit {
    top: 2vmin;
    // left: -1.5vmin;
  }
}

@media (max-aspect-ratio: 540/700) {
  .poker-patience__board {
    width: 80vmin;
  }

  .card {
    font-size: 6vmin;
  }

  .card__number {
    top: -2vmin;
    // left: 1.5vmin;
  }

  .card__suit {
    top: 2vmin;
    // left: -1.5vmin;
  }
}

@media (max-aspect-ratio: 440/700) {
  .poker-patience__board {
    width: 100vmin;
  }

  .card {
    font-size: 8vmin;
  }

  .card__number {
    top: -2.5vmin;
    // left: 1.5vmin;
  }

  .card__suit {
    top: 2.5vmin;
    // left: -1.5vmin;
  }
}
