.about__container {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 200;
  background-color: #4448;
}

.about__container > div {
  width: calc(100% - 80px);
  max-width: 400px;
  margin-left: auto;
  margin-right: auto;
  margin-top: 80px;
  margin-bottom: 80px;
  padding: 20px;
  border-radius: 20px;
  border: darkgoldenrod 10px solid;
  background-color: #444;
  color: white;
}

.about__scoring {
  margin-right: 10px;
}

.about__scoring-on {
  background-color: darkgoldenrod;
  padding: 2px 10px;
  border-radius: 6px;
  margin-right: 5px;
}

.poker-patience__unlimited .about__scoring-on {
  background-color: #57d;
}

.about__scoring-off {
  padding: 2px 10px;
  border-radius: 6px;
  margin-right: 5px;
  cursor: pointer;
}

.poker-patience__unlimited .about__container > div {
  border-color: #57d; 
}

@media (max-aspect-ratio: 680/700) {
  .about__container {
    background-color: transparent;
  }

  .about__container > div {
    margin-top: 80px;
    margin-bottom: 80px;
  }
}

@media (max-width: 345px) {
  .about__container > div {
    width: 100%;
    border: 0;
    border-radius: 0;
  }
}

.about {
  p:first-child {
    margin-top: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
  
  &__mail-to {
    color: white;
    text-decoration: none;
  }
}