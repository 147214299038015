.score__container {
  display: flex;
  flex-direction: column;
  align-items: center;

  text-align: center;
  cursor: default;
}

.score__title {
  margin-bottom: 10px;
  font-size: medium;
}

@media (max-width: 340px) {
  .score__title {
    font-size: small;
  }
}

.score__container-robot {
  cursor: pointer;
}

.score {
  font-size: 9vmin;
  margin-top: -1vmin;
}

.score__container-robot .score {
  font-size: 6vmin;
}

.poker-patience__daily .score__container-robot--clicked {
  color: darkgoldenrod;
}

.poker-patience__unlimited .score__container-robot--clicked {
  color: #57d;
}

.score__diff {
  position: relative;
  top: -1vmin;
  font-size: 2vmin;
}
