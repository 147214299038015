.board {
  background-color: darkgoldenrod;
  margin: 1.7vmin;
  padding: 1.7vmin;
  display: grid;
  grid-template-columns: calc(20% - 0.8px) calc(20% - 0.8px) calc(20% - 0.8px) calc(20% - 0.8px) calc(20% - 0.8px);
  grid-gap: 1px;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
}

.poker-patience__unlimited .board {
  background-color: #57d;
}

.board__square {
  position: relative;
  background-color: white;
  color: black;
  aspect-ratio: 1;
  cursor: default;
}

.board__empty_square {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  font-style: italic;
  font-size: small;
}

.board__empty_square {
  cursor: pointer;
}

.board__hand-container {
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.board__hand-background {
  position: absolute;
  width: 100%;
  height: 100%;
  /* background-color: gray;
  opacity: 0.15; */
}

.board__hand {
  position: absolute;
  width: 100%;
  height: 100%;
  text-align: center;
  font-size: 1.3vmin;
}

.board__row-hand {
  letter-spacing: 0.1vmin;
}

.board__column-hand {
  writing-mode: vertical-lr;
  text-orientation: upright;
  letter-spacing: -0.35vmin;
}

.board__row-hand > div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1.7vmin 1fr 1.7vmin;
  grid-template-rows: 1.7vmin 1fr 1.7vmin;

  & > div:nth-child(1),
  & > div:nth-child(3),
  & > div:nth-child(5),
  & > div:nth-child(7) {
    aspect-ratio: 1;
  }

  & > div:nth-child(4) {
    grid-column: 1 / 4;
  }
}

.board__column-hand > div {
  position: absolute;
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 1.7vmin 1fr 1.7vmin;
  grid-template-rows: 1.7vmin 1fr 1.7vmin;

  & > div:nth-child(1),
  & > div:nth-child(3),
  & > div:nth-child(7),
  & > div:nth-child(9) {
    aspect-ratio: 1;
  }
}

@media (max-aspect-ratio: 690/700) {
  .board__row-hand > div, .board__column-hand > div {
    grid-template-columns: 1.1vmin 1fr 1.1vmin;
    grid-template-rows: 1.1vmin 1fr 1.1vmin;
  }

  .board__hand {
    font-size: 0.9vmin;
  }
}

@media (max-aspect-ratio: 600/700) {
  .board__row-hand > div, .board__column-hand > div {
    grid-template-columns: 1.2vmin 1fr 1.2vmin;
    grid-template-rows: 1.2vmin 1fr 1.2vmin;
  }

  .board__hand {
    font-size: 1vmin;
  }
}

@media (max-aspect-ratio: 540/700) {
  .board__row-hand > div, .board__column-hand > div {
    grid-template-columns: 1.4vmin 1fr 1.4vmin;
    grid-template-rows: 1.4vmin 1fr 1.4vmin;
  }

  .board__hand {
    font-size: 1.1vmin;
  }
}

@media (max-aspect-ratio: 440/700) {
  .board__row-hand > div, .board__column-hand > div {
    grid-template-columns: 1.7vmin 1fr 1.7vmin;
    grid-template-rows: 1.7vmin 1fr 1.7vmin;
  }

  .board__hand {
    font-size: 1.3vmin;
  }
}

$pair-colour: #20abd9;
$two_pair-colour: #20d9d9;
$flush-colour: #abd920;
$three-colour: #20d9ab;
$full_house-colour: #7dd920;
$straight-colour: #20d97d;
$four-colour: #d9d920;
$straight_flush-colour: goldenrod;
$royal_flush-colour: gold;

@mixin corners($colour) {
  &.board__row-hand > div > div:nth-child(1) {
    background: linear-gradient(-135deg, $colour 50%, transparent 50%);
  }

  &.board__row-hand > div > div:nth-child(3) {
    background: linear-gradient(135deg, $colour 50%, transparent 50%);
  }

  &.board__row-hand > div > div:nth-child(5) {
    background: linear-gradient(-45deg, $colour 50%, transparent 50%);
  }

  &.board__row-hand > div > div:nth-child(7) {
    background: linear-gradient(45deg, $colour 50%, transparent 50%);
  }

  &.board__column-hand > div > div:nth-child(1) {
    background: linear-gradient(45deg, $colour 50%, transparent 50%);
  }

  &.board__column-hand > div > div:nth-child(3) {
    background: linear-gradient(135deg, $colour 50%, transparent 50%);
  }

  &.board__column-hand > div > div:nth-child(7) {
    background: linear-gradient(-45deg, $colour 50%, transparent 50%);
  }

  &.board__column-hand > div > div:nth-child(9) {
    background: linear-gradient(-135deg, $colour 50%, transparent 50%);
  }
}

.board__hand-pair {
  z-index: 2;

  & .board__hand-label {
    background: $pair-colour;
  }

  @include corners($pair-colour);
}

.board__hand-two_pair {
  z-index: 3;

  & .board__hand-label {
    background: $two_pair-colour;
  }

  @include corners($two_pair-colour);
}

.board__hand-flush {
  z-index: 4;

  & .board__hand-label {
    background: $flush-colour;
  }

  @include corners($flush-colour);
}

.board__hand-three {
  z-index: 5;

  & .board__hand-label {
    background: $three-colour;
  }

  @include corners($three-colour);
}

.board__hand-full_house {
  z-index: 6;

  & .board__hand-label {
    background: $full_house-colour;
  }

  @include corners($full_house-colour);
}

.board__hand-straight {
  z-index: 7;

  & .board__hand-label {
    background: $straight-colour;
  }

  @include corners($straight-colour);
}

.board__hand-four {
  z-index: 8;

  & .board__hand-label {
    background: $four-colour;
  }

  @include corners($four-colour);
}

.board__hand-straight_flush {
  z-index: 9;

  & .board__hand-label {
    background: $straight_flush-colour;
  }

  @include corners($straight_flush-colour);
}

.board__hand-royal_flush {
  z-index: 10;

  & .board__hand-label {
    background: $royal_flush-colour;
  }

  @include corners($royal_flush-colour);
}

.board__hand-nothing {
  z-index: 1;

  & .board__hand-label {
    background: lightgray;
    color: lightgray;
  }

  @include corners(lightgray);
}
