.winner {
  text-align: center;
  font-size: 4vmin;
  max-width: 30vmin;
  text-align: center;
  align-self: center;
}

.winner__play-again {
  margin-top: 2vmin;
  margin-bottom: 2vmin;
  cursor: pointer;
  color: #57d;
}
